$(function() {

  $(".numbers-row").append('<div class="inc button">+</div><div class="dec button">-</div>');

  $(".button").on("click", function() {

    var $button = $(this);
    var oldValue = $button.parent().find("input").val();
      var newVal;
      if ($button.text() === "+") {
          newVal = parseFloat(oldValue) + 1;
      } else {
	   // Don't allow decrementing below zero
      if (oldValue > 0) {
          newVal = parseFloat(oldValue) - 1;
      } else {
        newVal = 0;
      }
	  }

    $button.parent().find("input").val(newVal);

  });

});