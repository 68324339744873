$(document).ready(function() {


	$('.flexslider-one').flexslider({
		
		animation: "slide"
		
	});
	
	
	
	$('.menu-inn').wrapInner('<div class="mm1"></div>');
	var h1 = $(window).height();
	$('.menu-ins').css('height', h1);
	
	$('#header .search .top h6.deff').click(function () {
		
		$(this).parent('.top').parent('.search').find('.menu-in').fadeIn();
		$('body').addClass('mshow1');
		
	});
	
	
	$('#header .search .top h6.deff1').click(function () {
		
		$(this).parent('.top').parent('.search').find('.menu-in').fadeOut();
		$('body').removeClass('mshow1');
		
	});
	
	
	if ($(window).width() > 767) {
	   
	//$('.mm1').css('height', h1);
	//$('.mm1').css('overflow', 'scroll');
	
	$('#header .menu .top h6.deff').click(function () {
		
		$(this).parent('.top').parent('.menu').find('.menu-in').fadeIn();
		$('body').addClass('mshow');
		$('.wrapper').css('overflow', 'hidden');
		
	});
	
	
	$('#header .menu .top h6.deff1').click(function () {
		
		$(this).parent('.top').parent('.menu').find('.menu-in').fadeOut();
		$('body').removeClass('mshow');
		
	});
	
	
	
	}
	
	else {
		
		
		
		$('#header .menu .top h6.deff').click(function () {
		
		//$(this).parent('.top').parent('.menu').find('.menu-in').fadeIn();
		$('body').addClass('mshow');
		$('.wrapper').css('overflow', 'hidden');
		$('.menu').addClass('opened');
		$('.menu-bg').addClass('opened');
		
	});
	
	
	$('#header .menu .top h6.deff1').click(function () {
		
		//$(this).parent('.top').parent('.menu').find('.menu-in').fadeOut();
		$('body').removeClass('mshow');
		$('.menu').removeClass('opened');
		$('.menu-bg').removeClass('opened');
		
	});
		
	   
	}



	
	
	$('ul li:first-child').addClass('first');
	$('ul li:last-child').addClass('last');

	var sync1 = $("#sync1");
  var sync2 = $("#sync2");
 
  sync1.owlCarousel({
    singleItem : true,
    slideSpeed : 1000,
    navigation: true,
    pagination:true,
    afterAction : syncPosition,
    responsiveRefreshRate : 200,
	autoPlay : true
  });
 
  sync2.owlCarousel({
    items : 2,
    itemsDesktop      : [1199,2],
    itemsDesktopSmall     : [979,2],
    itemsTablet       : [768,2],
    itemsMobile       : [479,2],
	navigation: true,
    pagination:false,
    responsiveRefreshRate : 100,
    afterInit : function(el){
      el.find(".owl-item").eq(0).addClass("synced");
    }
  });
 
  function syncPosition(){
    var current = this.currentItem;
      $("#sync2")
          .find(".owl-item")
          .removeClass("synced")
          .eq(current)
          .addClass("synced");
    if($("#sync2").data("owlCarousel") !== undefined) {
        center(current);
    }
  }
 
  $("#sync2").on("click", ".owl-item", function(e){
    e.preventDefault();
    var number = $(this).data("owlItem");
    sync1.trigger("owl.goTo",number);
  });
 
  function center(number){
    var sync2Visible = sync2.data("owlCarousel").owl.visibleItems;
    var num = number;
    var found = false;
    for(var i in sync2Visible) {
          if (sync2Visible.hasOwnProperty(i)) {
              if (num === sync2Visible[i]) {
                  found = true;
              }
          }
      }

      if(found===false){
      if(num>sync2Visible[sync2Visible.length-1]) {
          sync2.trigger("owl.goTo", num - sync2Visible.length + 2);
      }else{
        if(num - 1 === -1){
          num = 0;
        }
        sync2.trigger("owl.goTo", num);
      }
    } else if(num === sync2Visible[sync2Visible.length-1]) {
        sync2.trigger("owl.goTo", sync2Visible[1]);
    } else if(num === sync2Visible[0]) {
        sync2.trigger("owl.goTo", num - 1);
    }
    
  }
  
  $(".select0 select").selectbox();
  
  $('.home-banner .social .arr').click(function () {
	  
	  $('.home-banner .social').toggleClass('ss');
	  
  });
  
  
  if ($(window).width() > 768) {
  $(window).scroll(function () {
   
		if($(this).scrollTop() > 0){
			$('#header').addClass('sticky', 1000);
		}if($(this).scrollTop() === 0){
			$('#header').removeClass('sticky');
		}

		
			
	});
  }
  
 /* $('#header .user h6').click(function () {
	  
	 $('.user .user-in').slideToggle(); 
	  
  });*/
	
	
	
	$('#header .user.desktopw').clone().appendTo('#header .user-mob');
	
  

});

	

$(document).ready(function() {
	
	// ----------- ALL TABS ------------ //	
		//$("ul.tabs li").css('background','#3c87cb');
		//$("ul.tabs3 li").css('background','#fff');
		
		
		//Default Action
		$(".tab_content").hide(); //Hide all content
		$("ul.tabs li:first").addClass("active").show(); //Activate first tab
		$(".tab_content:first").show(); //Show first tab content
	
		//On Click Event
		$("ul.tabs li").click(function() {
			$("ul.tabs li").removeClass("active"); //Remove any "active" class
			$(this).addClass("active"); //Add "active" class to selected tab
			$(".tab_content").hide(); //Hide all tab content
			var activeTab = $(this).find("a").attr("href"); //Find the rel attribute value to identify the active tab + content
			$(activeTab).fadeIn(); //Fade in the active content
			return false;
			
			
			
		});
	// ----------------------------------- //


});

    
$(window).load(function () {
	
	
	var relmax = -1;
	$('.sectionbox').each(function() {
		var h = $(this).height(); 
		relmax = h > relmax ? h : relmax;
	});
	$('.sectionbox').css('min-height',relmax);
	$('.sectionbox .li-in').css('min-height',relmax - 30);
	
	
	$('#header .user h6').click(function () {
	  
		$('.user .user-in').slideToggle(); 
	  
  	});
  
});


